<template>
  <el-form
    :model="sensor"
    :ref="refs.sensorsValidation"
    class="h-full w-2/3 overflow-auto relative"
    :class="{ 'overflow-hidden': isAllChecked }"
  >
    <div
      class="object-sensors__details flex flex-col gap-5 py-4"
      :class="{ 'opacity-10': isAllChecked }"
    >
      <div class="flex gap-3 px-4">
        <div class="w-full relative">
          <span class="object-sensors__details__label input-label">
            {{ $t('new_units.objects.fields.sensors.fields.name') }}
          </span>
          <el-form-item :rules="rules.name" prop="name">
            <el-input v-model="sensor.name" @focus="$event.target.select()" />
          </el-form-item>
        </div>
        <div class="w-full relative">
          <span class="object-sensors__details__label input-label">
            {{ $t('new_units.objects.fields.sensors.fields.sensor_type') }}
          </span>
          <el-form-item :rules="rules.type" prop="type">
            <el-select
              v-model="sensor.type"
              :placeholder="$t('new_units.placeholder.select')"
              :popper-append-to-body="false"
              filterable
              @change="selectType"
            >
              <el-option
                v-for="item in sensorTypes"
                :key="item.key"
                :label="item.value"
                :value="item"
              >
                <el-tooltip class="item" effect="dark" :content="item.value">
                  <span>{{ item.value }}</span>
                </el-tooltip>
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>
      <div class="object-technical__parameters">
        <TechnicalParameters
          :fields="props.sensor.custom_fields"
          :type-key="sensor.type.key"
          @onFieldDelete="deleteTechField"
          @onAddField="addTechField"
          @onChangeTechField="changeTechField"
        />
        <SensorsBundle
          v-if="isSensorBundleVisible"
          :fields="props.sensor.connected_sensors"
          :available-sensors="availableSensorsConnections"
          @onAddConnectedSensor="addConnectedSensor"
          @onDeleteConnectedSensor="deleteConnectedSensor"
        />
      </div>
      <div class="flex justify-between px-4">
        <UnitsCheckbox
          :value="sensor.hide_in_reports"
          :label="$t('new_units.objects.fields.sensors.fields.hide_in_report')"
          class="w-1/2"
          @onChange="changeHideInReport"
        />
        <UnitsCheckbox
          :value="sensor.hide_in_monitoring"
          :label="
            $t('new_units.objects.fields.sensors.fields.hide_in_monitoring')
          "
          class="w-1/2"
          @onChange="changeHideInMonitoring"
        />
      </div>

      <div class="flex gap-3 px-4 my-5">
        <div class="w-full relative">
          <span class="object-sensors__details__label input-label">
            {{ $t('sensors_details.sensor_validator') }}
          </span>
          <el-form-item prop="type">
            <el-select
              v-model="validator_sensor"
              :placeholder="$t('sensors_details.choose')"
              @change="
                validator_sensor
                  ? (sensor.validator_sensor = { id: validator_sensor })
                  : (sensor.validator_sensor = null)
              "
            >
              <el-option
                v-for="(item, idx) in dataSensors"
                :key="idx"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <img
              @click="close"
              v-if="validator_sensor"
              class="close-img"
              src="@/assets/icons/close(small).svg"
              alt=""
            />
          </el-form-item>
        </div>
        <div class="w-full relative">
          <p class="help-text pt-1" v-if="!validator_sensor">
            {{ $t('sensors_details.help_validator') }}
          </p>
          <div class="w-full relative" v-else>
            <span class="object-sensors__details__label input-label">
              {{ $t('sensors_details.validation_principle') }}
            </span>
            <el-form-item
              :error="
                validator_sensor && !validator_type ? 'Выберите валидатор' : ''
              "
            >
              <el-select
                v-model="validator_type"
                placeholder="Валидатор !=0"
                @change="
                  validator_type
                    ? (sensor.validator_type = { key: validator_type })
                    : (sensor.validator_type = null)
                "
              >
                <el-option
                  v-for="(item, idx) in validatorSensorTypes"
                  :key="idx"
                  :label="item.value"
                  :value="item.key"
                />
              </el-select>
            </el-form-item>
          </div>
        </div>
      </div>

      <div v-if="validator_sensor" class="additional-info mx-4 my-5">
        <img src="@/assets/icons/info.svg" alt="" />
        <span class="additional-info_monitoring">
          {{ $t('sensors_details.validation_info') }}
        </span>
      </div>

      <div class="flex items-center px-4">
        <SensorsTypes :activeType="activeType" @onChange="changeActiveType" />
        <span class="w-1/2 text-xs text-grey-200 font-normal ml-5">
          {{ $t('new_units.objects.fields.sensors.fields.help_info') }}
        </span>
      </div>
      <div v-if="activeType === types.FORMULA" class="relative px-4">
        <span class="input-label">
          {{ $t('new_units.objects.fields.sensors.fields.formula') }}
        </span>
        <el-form-item :rules="rules.formula" prop="formula">
          <el-select
            v-model="sensor.formula"
            :placeholder="$t('new_units.placeholder.select')"
            :popper-append-to-body="false"
            class="w-full"
          >
            <el-option
              v-for="item in allFormulas"
              :key="item.key"
              :label="item.value"
              :value="item.key"
            />
          </el-select>
        </el-form-item>
      </div>
      <div
        class="flex items-center justify-between gap-3 block px-4 object-sensors__details__smoothing"
      >
        <div class="flex-0">
          <span class="font-bold mainTitleColor">
            {{
              $t(
                'new_units.objects.fields.sensors.fields.parameters.type_filter'
              )
            }}
          </span>
        </div>
        <div class="flex-1" prop="sensor_filter_type">
          <el-select
            v-model="sensor.filter_type"
            :placeholder="$t('new_units.placeholder.select')"
            :popper-append-to-body="false"
            filterable
          >
            <el-option
              v-for="item in filterTypes"
              :key="item.key"
              :label="item.value"
              :value="item"
            >
              <el-tooltip class="item" effect="dark" :content="item.value">
                <span>{{ item.value }}</span>
              </el-tooltip>
            </el-option>
          </el-select>
        </div>
      </div>
      <div
        v-if="
          sensor.filter_type.key === 'median_filter' ||
          sensor.filter_type.key === 'median_kalman_filter'
        "
        class="flex items-center justify-between gap-3 block px-4 object-sensors__details__smoothing"
      >
        <div class="flex-1">
          <span class="demonstration font-bold mainTitleColor">
            {{
              $t(
                'new_units.objects.fields.sensors.fields.parameters.smoothing_coefficient'
              )
            }}
          </span>
          <span class="text-grey-200">
            {{
              $t(
                'new_units.objects.fields.sensors.fields.parameters.smoothing_coefficient_range'
              )
            }}
          </span>
          :
        </div>
        <el-form-item :rules="rules.smoothing" prop="smoothing" class="flex-1">
          <el-input v-model="sensor.smoothing" type="number" />
        </el-form-item>
      </div>
      <SensorsExpression
        v-if="activeType !== types.FORMULA"
        :expression="sensor.expression"
        :is-changed="isChanged"
        @onChange="sensor.expression = $event"
        @onInput="sensor.expression = $event"
        @undoChange="isChanged = false"
      />
      <SensorsParameters
        :parameters="currentParameters"
        :rule="[rules['param1.param_key'], rules['param2.param_key']]"
        :sensorId="sensorId"
        :string-converters="stringConverters"
        @onChangeSensorCalibration="changeSensorCalibration"
        @onAddRowSensorCalibration="addRowSensorCalibration"
        @onDeleteRowSensorCalibration="deleteRowSensorCalibration"
        @onGraphToggle="toggleGraph"
        @changeIsTextConverter="changeHideTextSensorsField"
      />
    </div>

    <SensorsDetailsDeletionOverlay
      v-if="isAllChecked"
      @onCancel="$emit('onCancelMassiveDeletion')"
    />
  </el-form>
</template>
<script setup>
import { computed, ref, watch, onMounted, nextTick, onBeforeMount } from 'vue'

import { useI18n } from '@/hooks/useI18n'
import { refs } from '@/components/unitsNew/helpers/index.js'
import { objectsApi } from '@/api'

import SensorsTypes from '@/components/unitsNew/components/Objects/Sections/Sensors/SensorsTypes.vue'
import TechnicalParameters from '@/components/unitsNew/components/Objects/Sections/Sensors/TechnicalParameters.vue'
import UnitsCheckbox from '@/components/unitsNew/components/UnitsCheckbox.vue'
import SensorsParameters from '@/components/unitsNew/components/Objects/Sections/Sensors/SensorsParameters.vue'
import SensorsExpression from '@/components/unitsNew/components/Objects/Sections/Sensors/SensorsExpression.vue'
import SensorsBundle from '@/components/unitsNew/components/Objects/Sections/Sensors/SensorsBundle.vue'
import SensorsDetailsDeletionOverlay from '@/components/unitsNew/components/Objects/Sections/Sensors/SensorsDetailsDeletionOverlay.vue'

import types from '@/enums/newUnits/sensors-types.js'
import { createVuexHelpers } from 'vue2-helpers'

const { useGetters, useActions, useState, useMutations } = createVuexHelpers()

const { invalidElements } = useGetters('units', ['invalidElements'])

const { sensorTypes, formulaTypes, filterTypes } = useGetters('dictionary', [
  'sensorTypes',
  'formulaTypes',
  'filterTypes'
])
const { getDictionaryByType } = useActions('dictionary', [
  'getDictionaryByType'
])

const { validator_sensor_type } = useState('dictionary', [
  'validator_sensor_type'
])

const {
  SET_SENSOR_CUSTOM_FIELDS,
  DELETE_SENSOR_CUSTOM_FIELD,
  ADD_SENSOR_CUSTOM_FIELD,
  CHANGE_SENSOR_CUSTOM_FIELD,
  ADD_CONNECTED_SENSOR,
  DELETE_CONNECTED_SENSOR,
  SET_IS_TEXT_CONVERTING,
  SET_INVALID_ELEMENTS
} = useMutations('units', [
  'SET_SENSOR_CUSTOM_FIELDS',
  'DELETE_SENSOR_CUSTOM_FIELD',
  'ADD_SENSOR_CUSTOM_FIELD',
  'CHANGE_SENSOR_CUSTOM_FIELD',
  'ADD_CONNECTED_SENSOR',
  'DELETE_CONNECTED_SENSOR',
  'SET_IS_TEXT_CONVERTING',
  'SET_INVALID_ELEMENTS'
])

const $t = useI18n()

const validator_sensor = ref('')
const validator_type = ref('')
const availableSensorsConnections = ref([])

const close = () => {
  validator_sensor.value = null
  props.sensor.validator_sensor = null
  validator_type.value = null
  props.sensor.validator_type = null
}

const emits = defineEmits(['changeSensorCalibration', 'onCalibrationChange'])

const props = defineProps({
  sensor: {
    type: Object,
    default: () => ({})
  },
  data: {
    type: Array,
    default: () => []
  },
  sensorCalibration: {
    type: Object,
    default: () => ({})
  },
  sensorId: String,
  isAllChecked: {
    type: Boolean,
    default: false
  }
})

const techFields = ref(props.sensor.custom_fields)
const dataSensors = computed(() => {
  return props.data.filter((val) => {
    return val.id !== props.sensorId && !val.isNew
  })
})

const stringConverters = ref(
  props.sensor.string_converters && props.sensor.string_converters.length
    ? props.sensor.string_converters
    : [{ x: '', y: { value: '' } }]
)

const isChanged = ref(false)

const resetInvalidParam = (prop) => {
  const newValues = invalidElements.value.filter((field) => field !== prop)

  SET_INVALID_ELEMENTS(newValues)
}

const checkParam = (paramNumber, invalidParam) => {
  return props.sensor.formula.includes(`S${paramNumber}`) && invalidParam
}

const isInvalidParam = (paramKey) => {
  return (
    props.sensor[paramKey].param_key === undefined ||
    !props.sensor[paramKey].param_key
  )
}

const invalidParamsHandler = () => {
  const params = ['param1', 'param2']

  params.forEach((param, idx) => {
    if (checkParam(idx + 1, isInvalidParam(param))) {
      if (!invalidElements.value.includes(param + '.param_key'))
        SET_INVALID_ELEMENTS([...invalidElements.value, param + '.param_key'])
    } else {
      resetInvalidParam(param + '.param_key')
    }

    refs.sensorsValidation.value.validateField(param + '.param_key')
  })
}

const validateParameters = (rule, value, callback) => {
  const paramKey = rule.field.split('.')[0]
  const paramNumber = paramKey.slice(-1)

  if (props.sensor.formula === 'EXPR') {
    callback()
    resetInvalidParam(rule.field)
  } else if (checkParam(paramNumber, isInvalidParam(paramKey))) {
    callback(new Error('Please select or param1 or param2'))
  } else {
    callback()
    resetInvalidParam(rule.field)
  }
}

const validateFormula = (rule, value, callback) => {
  invalidParamsHandler()
  if (value) {
    callback()
  } else {
    callback(new Error())
  }
}

const validateSmoothing = (rule, value, callback) => {
  const reg = /^\d{1,3}$/

  if (value !== undefined && value.toString() && value.toString().match(reg)) {
    callback()
  } else {
    callback(new Error())
  }
}

const rules = {
  name: [
    {
      required: true,
      message: $t('new_units.objects.fields.sensors.fields.errors.name')
    }
  ],
  type: [
    {
      required: true,
      message: $t('new_units.objects.fields.sensors.fields.errors.type')
    }
  ],
  formula: [
    {
      validator: validateFormula,
      required: true,
      message: $t('new_units.objects.fields.sensors.fields.errors.formula')
    }
  ],
  smoothing: [
    {
      validator: validateSmoothing,
      required: true,
      message: $t('new_units.objects.fields.sensors.fields.errors.smoothing'),
      trigger: 'change'
    }
  ],
  'param1.param_key': [
    {
      validator: validateParameters,
      required: true,
      message: $t('new_units.objects.fields.sensors.fields.errors.parameter_1'),
      trigger: 'change'
    }
  ],
  'param2.param_key': [
    {
      validator: validateParameters,
      required: true,
      message: $t('new_units.objects.fields.sensors.fields.errors.parameter_2'),
      trigger: 'change'
    }
  ]
}

const getRightActiveType = () => {
  return props.sensor.formula === 'EXPR' ? types.EXPRESSION : types.FORMULA
}

const activeType = ref(getRightActiveType())

const allFormulas = formulaTypes.value.filter((item) => item.key !== 'EXPR')

const changeHideInReport = (value) => {
  props.sensor.hide_in_reports = value
}

const changeHideInMonitoring = (value) => {
  props.sensor.hide_in_monitoring = value
}

const changeHideTextSensorsField = (value) => {
  SET_IS_TEXT_CONVERTING({ id: props.sensorId, value })

  emits('changeSensorCalibration', {
    ...props.sensorCalibration,
    is_string_value: value
  })
}

const changeActiveType = (value) => {
  activeType.value = value

  if (value === types.EXPRESSION) {
    props.sensor.formula = 'EXPR'
  } else {
    props.sensor.formula = ''
  }
}

const toggleGraph = (idx) => {
  // Last element of "currentParameters" --- the object of SENSOR calibration and graphic
  if (idx === 2) {
    const newCalibration = {
      ...props.sensorCalibration,
      isGraphShown: !props.sensorCalibration.isGraphShown
    }
    emits('changeSensorCalibration', newCalibration)
  } else {
    props.sensor[`param${idx + 1}`] = {
      ...props.sensor[`param${idx + 1}`],
      isGraphShown: !props.sensor[`param${idx + 1}`].isGraphShown
    }
  }
}

const changeSensorCalibration = ({ table, tableValues }) => {
  props.sensorCalibration[table] = tableValues

  props.sensor[table] = tableValues

  emits('onCalibrationChange', {
    table,
    values: props.sensorCalibration[table]
  })
}

const addRowSensorCalibration = ({ table, tableValues }) => {
  if (props.sensor[table]) {
    props.sensorCalibration[table].push(tableValues)
    emits('onCalibrationChange', {
      table,
      values: props.sensorCalibration[table]
    })

    return
  }

  props.sensorCalibration[table] = [tableValues]

  emits('onCalibrationChange', {
    table,
    values: props.sensorCalibration[table]
  })
}

const deleteRowSensorCalibration = ({ table, idx }) => {
  nextTick(() => {
    props.sensorCalibration[table].splice(idx, 1)

    emits('onCalibrationChange', {
      table,
      values: props.sensorCalibration[table]
    })
  })
}

const currentParameters = computed(() => {
  return [props.sensor.param1, props.sensor.param2, props.sensorCalibration]
})

getDictionaryByType('validator_sensor_type')

watch(
  () => {
    validator_sensor.value = props.sensor.validator_sensor
      ? props.sensor.validator_sensor.id
      : null
    validator_type.value = props.sensor.validator_type
      ? props.sensor.validator_type.key
      : null

    return props.sensor
  },
  (newValue) => {
    isChanged.value = true
    stringConverters.value = newValue.string_converters || []

    activeType.value = getRightActiveType()
  }
)

watch(
  stringConverters,
  (newStringConverters) => {
    if (!newStringConverters || !newStringConverters.length) {
      stringConverters.value.push({ x: '', y: { value: '' } })
    }

    const nonEmptyStringConverters = newStringConverters.filter(
      (item) => item.x !== ''
    )

    props.sensor.string_converters = nonEmptyStringConverters
  },
  { deep: true }
)

watch(
  () => props.sensor.id,
  (newValue) => {
    stringConverters.value.push({ x: '', y: { value: '' } })
    if (newValue) {
      techFields.value = props.sensor.custom_fields
    }

    getSensorsConnections()
  }
)

watch(
  () => props.sensor.filter_type,
  (newValue) => {
    if (props.sensor.filter_type.key === 'no_filter') {
      props.sensor.smoothing = 0
    }
  }
)

const deleteTechField = (idx) => {
  DELETE_SENSOR_CUSTOM_FIELD({ id: props.sensorId, itemIdx: idx })
}

const addTechField = (item) => {
  ADD_SENSOR_CUSTOM_FIELD({ id: props.sensorId, item })
}

const changeTechField = ({ value, index, prop }) => {
  CHANGE_SENSOR_CUSTOM_FIELD({ id: props.sensorId, index, prop, value })
}

const addConnectedSensor = (id) => {
  ADD_CONNECTED_SENSOR({ sensorId: props.sensorId, id })
}

const deleteConnectedSensor = (id) => {
  DELETE_CONNECTED_SENSOR({ sensorId: props.sensorId, id })
}

const getSensorsConnections = () => {
  if (isSensorBundleVisible.value) {
    objectsApi.getSensorsConnections(
      (res) => {
        availableSensorsConnections.value = res.data[props.sensor.type.key]
      },
      (err) => {
        throw new Error(err)
      }
    )
  }
}

const selectType = () => {
  SET_SENSOR_CUSTOM_FIELDS({ id: props.sensorId, values: [] })

  getSensorsConnections()
}

const validatorSensorTypes = computed(() => {
  return validator_sensor_type.value
})

const isSensorBundleVisible = computed(() => {
  switch (props.sensor.type.key) {
    case 'fuelsensor':
      return true
    case 'instant_fuelcounter':
      return true
    case 'fuelcounter':
      return true
    default:
      return false
  }
})

onMounted(() => {
  stringConverters.value.push({ x: '', y: { value: '' } })

  props.sensor.param1.isGraphShown = false
  props.sensor.param2.isGraphShown = false
})

onBeforeMount(() => {
  getSensorsConnections()
})
</script>
<style scoped lang="scss">
.close-img {
  position: relative;
  bottom: 35px;
  left: 186px;
  cursor: pointer;
}
.help-text {
  font-size: 12px;
  line-height: 16.8px;
  font-weight: 400;
  color: #a6aeb8;
}
.additional-info {
  background: #dbebff;
  border-radius: 12px;
  display: flex;
  gap: 8px;
  padding: 10px 12px;
  margin-top: -42px;

  &_monitoring {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #20579a;
  }
}
</style>
